import moment from 'moment';
import { Teacher, ClassTimeslot, Subject, Room, YardDutySlot, YardDutyLocation, Student } from './classes.js';
import globals from '@/globals.js';
import timetable from './timetable.js';
import router from './router/index.js';
import { getLessonTimes } from './utility.js';

export default async function loadEverything(timetable_data) {
    console.log('loadEverything');
    timetable.empty();

    if (timetable_data.heads_of_house)
        timetable['heads_of_house'] = timetable_data.heads_of_house;

    Object.values(timetable_data.teachers).forEach(teacher => {
        teacher = new Teacher(teacher);
        timetable.teachers[teacher.SchoolStaffCode] = teacher;
    });


    var monday = moment().startOf('isoWeek');
    timetable_data.class_timeslots.forEach(class_timeslot => {
        if (typeof class_timeslot.period_short_name === 'undefined')
            return; //probably an R-7 class


        var lessonTime = null;
        try {
            lessonTime = getLessonTimes(class_timeslot.period_short_name, class_timeslot.cycle_day_index);
        } catch (e) {
            console.error(e);
        }
        if (!lessonTime) {
            console.warn('Ignoring class_timeslot', JSON.stringify(class_timeslot));
            return;
        }
        var start = moment.duration(lessonTime.start);
        var end = moment.duration(lessonTime.end);

        var day = monday.clone().add(class_timeslot.cycle_day_index - 1, 'days');


        var start = day.clone().add(start);
        var end = day.clone().add(end);

        class_timeslot.start = start;
        class_timeslot.end = end;

        class_timeslot = new ClassTimeslot(class_timeslot);

        var subject = timetable.subjects[class_timeslot.class_code];
        if (!subject) {
            subject = new Subject({ 'code': class_timeslot.class_code, 'name': class_timeslot.ClassDescription, 'timeslots': [] });
            timetable.subjects[class_timeslot.class_code] = subject;
        }
        var teacher = timetable.teachers[class_timeslot.teacher_short_name];
        if (!teacher) {
            //console.warn('No teacher', class_timeslot);
        } else {
            if (teacher.subjects.indexOf(class_timeslot.class_code) == -1)
                teacher.subjects.push(class_timeslot.class_code);
        }
        subject.timeslots.push(class_timeslot);


        timetable.rooms[class_timeslot.room_code] = new Room({ 'ROOM': class_timeslot.room_code, 'CODE': class_timeslot.room_code });
        timetable.class_timeslots.push(class_timeslot);
    });

    if (timetable_data.teacher_classes) {
        timetable_data.teacher_classes.map(teacher_class => {
            var subject = timetable.subjects[teacher_class['ClassCode']];
            if (!subject)
                return;
            var teacher = teacher_class['SchoolStaffCode'];
            if (!teacher)
                return;
            if (subject.teachers.indexOf(teacher) == -1) {
                subject.teachers.push(teacher);
            }
            return subject;
        });
    }

    timetable.yard_duties = [];
    timetable.yard_duty_locations = {};

    timetable_data.yard_duties = timetable_data.yard_duties || [];

    timetable_data.yard_duties.forEach(yard_duty => {
        var yard_duty_slot = new YardDutySlot(yard_duty);
        var teacher = timetable.teachers[yard_duty.SchoolStaffCode];
        if (teacher)
            teacher.yardDuties.push(yard_duty_slot);
        timetable.yard_duties.push(yard_duty_slot);

        if (!timetable.yard_duty_locations[yard_duty.ClassDescription]) {
            timetable.yard_duty_locations[yard_duty.ClassDescription] = new YardDutyLocation({
                ClassDescription: yard_duty.ClassDescription
            });
        }

        var ydl = timetable.yard_duty_locations[yard_duty.ClassDescription];
        ydl.yard_duties.push(yard_duty_slot);

    });


    Object.values(timetable_data.students).forEach(student => {
        student = new Student(student);
        timetable.students[student.StudentID] = student;
    });;

    timetable.covers = [];
    globals.loaded = true;
    console.log('Finished loading timetable data');

    if (globals.LOGIN_REDIRECT) {
        console.log('Redirecting to', globals.LOGIN_REDIRECT);
        router.push(globals.LOGIN_REDIRECT);
        globals.LOGIN_REDIRECT = null;
    } else if (router.currentRoute.value.name == 'login') {
        router.push('/');
    } else {
        console.log(router.currentRoute.value);
        //We have updated the timetable data after the first page load
        //So the user is already looking at a non-login route, and
        //we shouldn't redirect them.
    }
}
