import moment from 'moment';
import { sortBy } from '@/utility.js';
import config from '@/config.js';
import * as Sentry from "@sentry/browser";
import timetable from '@/timetable.js';
import { graphClient, msalClient } from './login';

var DUTY_ROOM_NAMES = {
    'BC': 'Basketball Court',
    'P1': 'Park 1',
    'P2': 'Park 2',
    'QU': 'Quad',
    'SL': 'SL Courtyard',
    'LG': 'Library Green',
    'C4': 'C4SL (Level1/Basement)',
    'ST': 'South Terrace',
    'TS': 'Tuckshop',
    'PC': 'Prep Court'
}

export var lessonTimes = [
    { short_name: 'AM', start: '8:15', end: '8:35', 'duty': true, 'synergy': 'YD8:10-8:30' },
    { short_name: 'T', start: '8:30', end: '8:40', 'duty': false, 'synergy': 1 },
    { short_name: '1', start: '8:45', end: '9:30', 'duty': false, 'synergy': 2 },
    { short_name: '2', start: '9:35', end: '10:20', 'duty': false, 'synergy': 3 },
    { short_name: 'R', start: '10:20', end: '10:40', 'duty': true, 'synergy': 'YD10:20-10:40' },
    { short_name: '3', start: '10:45', end: '11:30', 'duty': false, 'synergy': 4 },
    { short_name: '4', start: '11:35', end: '12:20', 'duty': false, 'synergy': 5 },
    { short_name: '5', start: '12:25', end: '13:10', 'duty': false, 'synergy': 6 },
    { short_name: 'L1', start: '13:10', end: '13:30', 'duty': true, 'synergy': 'YD13:10-13:30' },
    { short_name: 'L2', start: '13:30', end: '13:50', 'duty': true, 'synergy': 'YD13:30-13:50' },
    { short_name: '6', start: '13:55', end: '14:40', 'duty': false, 'synergy': 7 },
    { short_name: '7', start: '14:45', end: '15:30', 'duty': false, 'synergy': 8 },
    { short_name: 'PM', start: '15:30', end: '16:00', 'duty': true, 'synergy': 'YD15:30-15:50' },
]
window.lessonTimes = lessonTimes;

moment.prototype.moveToCurrentWeek = function () {
    //add/subtract weeks as necessary, so that the date is in the current week
    var weeksDiff = moment().startOf('isoWeek').diff(this.clone().startOf('isoWeek'), 'week');
    this.add(weeksDiff, 'week');
    return this;
}

export class Subject {
    constructor(data) {
        Object.assign(this, data);
        if (!this.timeslots)
            this.timeslots = [];
        if (!this.teachers)
            this.teachers = [];
        this.type = 'SUBJECT';
    }

    getTeacher() {
        var teacher_short_name = this.timeslots[0].teacher_short_name;
        return timetable.teachers[teacher_short_name];
    }
    getAllTeachers() {
        var teacher_short_names = new Set();
        this.timeslots.forEach(t => teacher_short_names.add(t.teacher_short_name));
        this.teachers.forEach(teacher_short_name => teacher_short_names.add(teacher_short_name));

        var teachers = Array.from(teacher_short_names).map(teacher_short_name => timetable.teachers[teacher_short_name]);
        teachers = teachers.filter(_ => _); //If a teacher has no subjects, they will not exist in timetable.teachers. They might have a yard duty though.
        return teachers;
    }

    getStudents() {
        return Object.values(timetable.students).filter(x => x.subjects.indexOf(this.code) !== -1).sort(sortBy('SURNAME'))
    }

    getName() {
        if (this.name)
            return this.name;
        if (this.code.indexOf('Duty') == 0) {
            var dutyName = DUTY_ROOM_NAMES[this.code.substr(5)];
            if (dutyName)
                return 'Duty: ' + dutyName
        }
        return this.code;
    }

    getTimeslots() {
        return this.timeslots;
    }

    getLocation(cycle_day_index, period_short_name) {
        if (typeof cycle_day_index == 'undefined') {
            return this.getLocationAtTime();
        }
        var timeslots = this.getTimeslots();
        return timeslots.filter(timeslot => timeslot.cycle_day_index == cycle_day_index && timeslot.period_short_name == period_short_name);
    }

    getYearLevel() {
        return parseInt(this.code.substr(0, 2));
    }
}

export class Student {
    constructor(data) {
        Object.assign(this, data);
        if (!this.subjects)
            this.subjects = [];
        this.type = 'STUDENT';
    }

    getName() {
        return this.getPreferredName() + ' ' + this.StudentSurname;
    }

    async getPhotoURL() {
        if (!this.StudentOccupEmail)
            return ''; //some students do not have an email in Synergetic
        var graph_endpoint = 'users/' + this.StudentOccupEmail + '/photo/$value';
        try {
            var imageBlob = await graphClient
                .api(graph_endpoint)
                .get();
        } catch (e) {
            console.warn("Error getting photo for student", this, e);
            return '';
        }
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(imageBlob);
        return imageUrl;
    }


    getPreferredName() {
        return this.StudentPreferred;
    }

    getFirstName() {
        return this.StudentPreferredFormal;
    }

    getTeachers() {
        return this.subjects.map(code => {
            if (!timetable.subjects[code])
                return null;
            return timetable.subjects[code].getTeacher()
        }).filter(x => x); //filter out null/undefined
    }

    getHouse() {
        return this.StudentHouse;
    }

    getYearLevel() {
        if (!this.StudentYearLevel)
            return null;
        if (typeof this.StudentYearLevel == 'number')
            return this.StudentYearLevel
        return this.StudentYearLevel.replace(/^0+/, '');
    }

    getTutorGroup() {
        if (this.StudentTutor)
            return this.StudentTutor;
        if ([7, 8, 9, 10, 11, 12].indexOf(this.StudentYearLevel) !== -1) {
            return this.StudentHouse + this.StudentYearLevel;
        }
    }

    getTutorTeachers() {
        var tutorClass = this.getClasses().find(x => x.code.indexOf('TUT') !== -1)
        if (!tutorClass)
            return [];
        var tutors = tutorClass.getAllTeachers();
        if (!tutors)
            return [];
        return tutors;
    }

    getHeadsOfHouse() {
        var house = this.getHouse();
        if (!timetable.heads_of_house)
            return null;
        var year_level = this.getYearLevel();
        var SchoolStaffCode = null;
        if ([7, 8, 9, 10, 11, 12].indexOf(year_level) === -1)
            return null;
        if (!timetable.heads_of_house[house])
            return null;
        if (timetable.heads_of_house[house].length < 2)
            return null;
        var SchoolStaffCodes = timetable.heads_of_house[house];
        if (!SchoolStaffCodes || SchoolStaffCodes.length === 0)
            return null;
        var teachers = []
        SchoolStaffCodes.forEach(SchoolStaffCode => {
            var teacher = timetable.teachers[SchoolStaffCode];
            if (teacher)
                teachers.push(teacher);
        });
        if (teachers.length === 0)
            return null;
        return teachers;
    }

    getClasses() {
        return this.subjects.map(code => timetable.subjects[code]).filter(_ => _);
    }

    getLocation(cycle_day_index, period_short_name) {
        if (typeof cycle_day_index == 'undefined') {
            return this.getLocationAtTime();
        }
        var timeslots = this.getTimeslots();
        return timeslots.filter(timeslot => timeslot.cycle_day_index == cycle_day_index && timeslot.period_short_name == period_short_name);
    }



    getTimeslots() {
        var subjects = this.getClasses();
        var timeslots = subjects.reduce((prev, curr) => prev.concat(curr.timeslots), []);
        return timeslots
    }

    getLocationAtTime(timeToCheck) {
        if (!timeToCheck)
            timeToCheck = moment();
        timeToCheck.moveToCurrentWeek();
        var all_timeslots = this.getTimeslots();
        var current_timeslots = all_timeslots.filter(timeslot => timeToCheck.isBetween(timeslot.start, timeslot.end));
        return current_timeslots;
    }
}



export class Teacher {
    constructor(data) {
        Object.assign(this, data);
        if (!this.subjects)
            this.subjects = [];
        if (!this.yardDuties)
            this.yardDuties = [];
        this.type = 'TEACHER';
    }

    getName() {
        return this.StaffPreferredName + ' ' + this.StaffSurname;
    }

    getHouse() {
        var tutorClass = this.getClasses().find(c => c.getName().indexOf('Tutor Group') !== -1);
        if (!tutorClass)
            return null;
        var house = ['KM', 'BH', 'CN', 'MS'].find(house => tutorClass.name.indexOf(house) !== -1);
        return house;
    }

    getClasses() {
        return Object.values(timetable.subjects).filter(subject => subject.teachers.indexOf(this.SchoolStaffCode) !== -1);
    }

    getTimeslots() {
        var subjects = this.getClasses();
        var timeslots = subjects.reduce((prev, curr) => prev.concat(curr.timeslots), []);

        /*
        Some subjects are taught by multiple teachers at different times of the week (eg: part-time in Junior School)
        Check that this lesson (or yard duty) is actually taught by this teacher.
        However, there are a few subjects with team teachers, where one teacher is listed as free in Synergetic.
        But they are actually busy teaching! These are exceptions to the rule, and is working around problems with Synergetic data.
        */
        const TEAM_TEACHER_SUBJECTS = ['Tutor', 'Creative Industries', 'Pastoral', 'Wellbeing']
        timeslots = timeslots.filter(t =>
            t.teacher_short_name == this.SchoolStaffCode
            || TEAM_TEACHER_SUBJECTS.some(subjectName =>
                t.ClassDescription.indexOf(subjectName) !== -1)
        );

        /*
        Sometimes, you are not allocated as the teacher of a subject.
        But you are teaching at least one lesson of it!
        For example, 03 TechnologiesS.
        */
        timetable.class_timeslots.forEach(timeslot => {
            if(timeslot.teacher_short_name == this.SchoolStaffCode
                && timeslots.indexOf(timeslot) === -1) {
                timeslots.push(timeslot)
            }
        });

        var mySubstitutions = timetable.substitutions.filter(substitition => substitition.replacingTeacher && substitition.replacingTeacher.SchoolStaffCode == this.SchoolStaffCode)
        mySubstitutions = mySubstitutions.map(x=>x.getTimeslot());
        mySubstitutions = mySubstitutions.filter(_=>_); //Ignore null substitutions. This can happen if the substituion is for a timeslot that doesn't actually exist.
        timeslots = [...timeslots, ...mySubstitutions];

        return timeslots;
    }

    getLocationAtTime(timeToCheck) {
        if (!timeToCheck)
            timeToCheck = moment();

        timeToCheck.moveToCurrentWeek();

        var all_timeslots = this.getTimeslots();
        var current_timeslots = all_timeslots.filter(timeslot => timeToCheck.isBetween(timeslot.start, timeslot.end));
        return current_timeslots;
    }

    getLocation(cycle_day_index, period_short_name) {
        if (typeof cycle_day_index == 'undefined') {
            return this.getLocationAtTime();
        }

        var all_slots = [];


        var timeslots = this.getTimeslots();

        var COVERS_ENABLED = false;
        if (COVERS_ENABLED) {
            var covers = window.covers.filter(cover => cover.replacement_teacher == this.getName());
            var cover_timeslots = covers.map(cover => {
                var cover_timeslot = cover.getTimeslot();
                cover_timeslot = Object.assign({}, cover_timeslot);
                cover_timeslot.isCover = true;
                all_slots = all_slots.push(cover_timeslot);
            });
            timeslots = cover_timeslots.concat(this.getTimeslots());
        }


        timeslots = timeslots.filter(timeslot => timeslot.cycle_day_index == cycle_day_index && timeslot.period_short_name == period_short_name);

        all_slots = all_slots.concat(timeslots);

        var yard_duty_slots = this.yardDuties.filter(yard_duty_slot => yard_duty_slot.DayNumber == cycle_day_index && yard_duty_slot.getID() == period_short_name);
        all_slots = all_slots.concat(yard_duty_slots);

        return all_slots;
    }



    async getPhotoURL() {
        if (!this.StaffOccupEmail)
            return '';
        var graph_endpoint = 'users/' + this.StaffOccupEmail + '/photo/$value';
        try {
            var imageBlob = await graphClient
                .api(graph_endpoint)
                .get();
        } catch (e) {
            console.warn("Error getting photo for teacher", this, e);
            return '';
        }
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(imageBlob);
        return imageUrl;
    }
}

export class Room {


    constructor(data) {
        Object.assign(this, data);
        this.type = 'ROOM';
    }

    getName() {
        return this.ROOM;
    }

    //TODO: This is repeated in Teacher, etc.
    getLocationAtTime(timeToCheck) {
        if (!timeToCheck)
            timeToCheck = moment();

        timeToCheck.moveToCurrentWeek();

        var all_timeslots = this.getTimeslots();
        var current_timeslots = all_timeslots.filter(timeslot => timeToCheck.isBetween(timeslot.start, timeslot.end));
        return current_timeslots;
    }

    getTimeslots() {
        return timetable.class_timeslots.filter(timeslot => timeslot.room_code == this.CODE)
    }


    //TODO: This is repeated in Teacher, etc.
    getLocation(cycle_day_index, lessonTime) {
        if (typeof cycle_day_index == 'undefined') {
            return this.getLocationAtTime();
        }

        var timeslots = this.getTimeslots();

        return timeslots.filter(timeslot => timeslot.cycle_day_index == cycle_day_index && timeslot.period_short_name == lessonTime);
    }

    isSeniorSchoolRoom() {
        return this.getTimeslots().some(timeslot =>
            ['07', '08', '09', '10', '11', '12'].indexOf(timeslot.class_code.substr(0, 2)) !== -1
        )
    }
}

export class ClassTimeslot {

    constructor(data) {
        Object.assign(this, data);
        this.type = 'CLASS_TIMESLOT'
    }

    getClass() {
        return timetable.subjects[this.class_code];
    }
    getTeacher() {
        return timetable.teachers[this.teacher_short_name];
    }
    getRoom() {
        return timetable.rooms[this.room_code];
    }
    getLessonTime() {
        return lessonTimes.find(lt => lt.synergy == timeslot.period_short_name)
    }
    getDateForThisWeek(startDate) {
        //assuming that this timeslot is weekly,
        //determine the date for this week's lesson
        if (!startDate)
            startDate = moment();
        var start = this.start.clone();
        var weeksAhead = start.diff(moment(), 'week');
        start.add(weeksAhead);
        return start;
    }
    toString() {
        var days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
        var dayIndex = parseInt(this.cycle_day_index) - 1;

        var period_name = lessonTimes.find(lt => lt.synergy == this.period_short_name).short_name;
        return days[dayIndex] + ' L' + period_name
    }
}

export class YardDutySlot {
    constructor(data) {
        Object.assign(this, data);
        this.type = 'YARD_DUTY_SLOT'
    }

    getID() {
        return 'YD' + this.DefinitionTimeFrom + '-' + this.DefinitionTimeTo
    }

    getLessonTime() {
        console.warn('Need to adjust for day')
        return lessonTimes.find(lt => lt.synergy == this.getID());
    }

    getTeacher() {
        return timetable.teachers[this.SchoolStaffCode];
    }
}

export class YardDutyLocation {
    constructor(data) {
        Object.assign(this, data);
        this.type = 'YARD_DUTY_LOCATION'
        if (!this.yard_duties)
            this.yard_duties = [];
    }

    getName() {
        return this.ClassDescription;
    }
}

export class Cover {
    constructor(data) {
        Object.assign(this, data);
    }

    getTimeslot() {
        var cycle_day_index = moment().isoWeekday()
        return timetable.class_timeslots.find(timeslot => timeslot.period_short_name == this.lesson_short_code && timeslot.class_code == this.class_code.replace('_', ' ') && timeslot.cycle_day_index == cycle_day_index)
    }
}


export class SpeedyLogger { }

SpeedyLogger.getUser = function () {
    try {
        var accounts = msalClient.getAllAccounts();
        if (!accounts || accounts.length == 0)
            return '';
        var account = accounts[0];
        var email = account.username;
        Sentry.setUser({ email: email });
        return email;
    } catch (e) {
        return '';
    }
}

SpeedyLogger.log = function (action) {
    //if (location.hostname == 'localhost' || location.hostname == '127.0.0.1')
    //    return
    action = action || '';

    if (action.indexOf('/search') == 0)
        return

    if (action.indexOf('/code=') == 0 || action.indexOf('eyJ') !== -1)  //smells like an access token
        return

    var data = { 'username': SpeedyLogger.getUser(), 'action': action };
    fetch(config.SPEEDYLOG_URL, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
}
